import React from "react"

const ThankyouPage = () => {
  return (
    <React.Fragment>
      <h1>Thank you</h1>

      <p>Thanks for your order.</p>
    </React.Fragment>
  )
}

export default ThankyouPage
